/**
 * Tag
 * component
 */

.tag {
    background-color: $color-light;
    border-radius: 5em;
    color: $color-medium;
    display: inline-block;
    font-size: 0.75em;
    height: 25px;
    line-height: 25px;
    margin-right: ($gutter / 4) ($gutter / 4) 1px;
    padding: 0 ($gutter / 2);
    white-space: nowrap;

    /** Modifiers **/

    &.-primary {
        background-color: $color-primary;
        color: $color-base !important;
    }

    &.-secondary {
        background-color: $color-secondary;
        color: $color-base !important;
    }

    &:last-child {
        margin-right: 0;
    }

    &:hover {
        background-color: $color-secondary;
        text-decoration: none;
        color: $color-base !important;
        transition: background-color 0.6s ease-out;
    }

    /** States **/

    &.-secondary {
        &:hover {
            background-color: $color-dark;
            color: $color-base !important;
        }
    }

    /** Breakpoints **/
}
