/**
 * Ag-grid overwrite styles
 */

 .ag-theme-alpine .ag-cell {
     line-height: 1em;
     padding-top: 8px;
 }

 .ag-cell-wrap-text {
     word-break: normal;
 }