/**
 * sectionTitle
 * component
 */

 .sectionTitle {

    @include clearfix();

    margin-bottom: $space;

    &__title {
        color: $color-dark;
        font-size: 2.250em;
        font-family: $font-primary;
    }

    &__actions {
        margin-top: $gutter / 2;
        text-align: left;
    }

    /** Modifiers **/

    &.-small-bottom {
        margin-bottom: $gutter / 2;
    }

    /** States **/

    /** Breakpoints **/

    @media screen and (min-width: 900px) {
        
        &__title {
            @include column(8/12, $cycle: 0, $offset: 0);
        }

        &__actions {
            @include column(4/12, $cycle: 0, $offset: 0);
            text-align: right;
        }

    }

 }