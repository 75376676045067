/**
 * ListCollapse
 * component
 */

 .listCollapse {
  background-color: $color-base;
  border-bottom: 1px solid $color-light;
  color: $color-base;
  margin: 0 0 $gutter;
  padding: 0;
  // padding: 20px;

  &__item {
    width: 100%;
  }

  &__itemTitle {

    @extend %list-button;

    background-color: $color-base;
    color: $color-medium;
    border-top: 1px solid $color-light;
    padding: ($gutter / 2) 0;

    &:after {
      content: none;
    }

    &:hover {
      color: $color-medium;
    }

  }

  &__button {
    
    @extend %list-button;

    background-color: $color-base;
    color: $color-medium;
    border-top: 1px solid $color-light;
    padding: ($gutter / 2) 0;
    padding-right: $gutter;

    &:after {
      content: $icon-angle-down;
      transform: rotate(0deg);
      transition: transform .4s ease-in-out;
    }

    &:hover {
      color: $color-medium;
    }

  }

  &__content {
    background-color: rgba($color-base, .6);
    color: $color-medium;
    max-height: 0;
    overflow: hidden;
    transition: max-height .6s ease-out;
  }

  &__content-body {
    padding: ($gutter / 4) 0;
  }

  /** States **/

  &__item.-is-open & {

    &__button:after {
      transform: rotate(180deg);
    }

    &__content {
      max-height: 100em;
      transition: max-height .9s ease-in-out;
    }
 
  }

  &__item.-is-always-open & {

    &__button:after {
      content: none;
    }

    &__content {
      max-height: none;
    }

  }

}