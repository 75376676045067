/**
 * Layout
 * component
 */

.layout {
  align-content: stretch;
  align-items: stretch;
  display: flex;
  height: 100vh;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100vw;

  &__page,
  &__content {
    flex-grow: 1;
    overflow: auto;
  }

  &__page {
    align-items: stretch;
    align-content: stretch;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
  }

  &__sidebar,
  &__content {
    position: relative;
    -webkit-overflow-scrolling: touch;
  }


  /** Breakpoints **/

  @media screen and (max-width: $breakpoint-sidebar) {
    .layout {
      height: 100vh;
        &__page,
        &__content {
          overflow: inherit;
        }
    }
  }
  
  @media screen and (min-width: $breakpoint-sidebar) {
    &__page {
      flex-direction: row;
      overflow: hidden;
    }
  }

  @media screen and (min-width: $breakpoint-header) {
    flex-direction: row;
  }
}
