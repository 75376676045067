/**
 * Button
 * component
 */

.btn-primary {
    background-color: #499c94 !important;
    border-color: #fff !important;
}
.btn-primary:hover {
    background-color: #499c94 !important;
    border-color: #000 !important;
}
.btn-primary.active,
.btn-primary:active,
.btn-primary.focus,
.btn-primary:focus,
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
    background-color: #2e9afa;
    border-color: #2e9afa;
}
/* SUCCESS BUTTON */
.btn-success {
    background-color: #499c94;
    border-color: #fff;
}
.btn-success:hover {
    background-color: #499c94;
    border-color: #000;
}
.btn-success.active,
.btn-success:active,
.btn-success.focus,
.btn-success:focus,
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
    background-color: #d9edeb;
    border-color: #fff;
}

/* INFO BUTTON */

.btn-white {
    background-color: #fff;
    border-color: #d9edeb;
    color: #000;
    font: 0.8em;
    padding-top: 3px;
    vertical-align: top;
    padding-bottom: 3px;
}

.btn-lg {
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 14px;
}

.btn-info:hover {
    background-color: #d9edeb;
    border-color: #000;
    color: #000;
}
.btn-info.active,
.btn-info:active,
.btn-info.focus,
.btn-info:focus,
.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info:active.focus,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
    background-color: #d9edeb;
    border-color: #fff;
    color: #000;
}
/* WARNING BUTTON */
.btn-warning {
    background-color: #d9edeb;
    border-color: #fff;
    color: #000;
}
.btn-warning:hover {
    background-color: #d9edeb;
    border-color: #fff;
    color: #000;
}
.btn-warning.active,
.btn-warning:active,
.btn-warning.focus,
.btn-warning:focus,
.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning:active.focus,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
    background-color: #d9edeb;
    border-color: #fff;
    color: #000;
}
/* DANGER BUTTON */
// Commented out in order to use default danger colors which are more appropriate than the ugly #d9edeb
//.btn-danger {
//    background-color: #d9edeb;
//    border-color: #fff;
//    color: #000;
//}
//.btn-danger:hover {
//    background-color: #d9edeb;
//    border-color: #fff;
//    color: #000;
//}
.btn-danger.active,
.btn-danger:active,
.btn-danger.focus,
.btn-danger:focus,
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger:active.focus,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
    background-color: #d9edeb;
    border-color: #fff;
    color: #000;
}

.btn-info {
    background-color: $color-primary !important;
    border-color: $color-primary !important;
    color: white !important;
    text-decoration: none !important;
}
.btn-info:hover {
    background-color: $color-secondary !important;
    border-color: $color-secondary !important;
}

.button {
    background-color: $color-primary;
    border-radius: $radius-mid;
    color: $color-base;
    font-size: 0.875em;
    padding: 0.5em 1em;
    text-decoration: none;
    transition: background-color 0.6s ease-out;

    &__icon {
        background-color: rgba($color-medium, 0.12);
        border-radius: 50%;
        color: inherit;
        display: inline-block;
        vertical-align: middle;

        *[class^='icon-'] {
            display: table-cell;
            font-size: ($gutter / 3 * 1.12);
            height: $gutter;
            text-align: center;
            vertical-align: middle;
            width: $gutter;
        }
    }

    /** Modifiers **/

    &.-primary {
        background-color: $color-primary;
        color: $color-base !important;
        font-weight: 400;
    }

    &.-secondary {
        background-color: $color-light;
        color: $color-medium;
        font-weight: 300;
    }

    &.-light {
        background-color: $color-base;
        color: $color-medium !important;
        font-weight: 400;
    }

    &.-large {
        padding: 1em $gutter;
    }

    &.-noPadding {
        padding: 0px;
    }

    &.-noPaddingLeft {
        padding-left: 0;
    }

    &.-noPaddingRight {
        padding-right: 0;
    }

    &.-noPaddingTop {
        padding-top: 0;
    }

    &.-noPaddingBottom {
        padding-bottom: 0;
    }

    /** States **/

    &:disabled,
    &.is-disabled {
        background-color: $color-dark;
        color: $color-base;
        opacity: 0.5;
    }

    &:hover {
        background-color: $color-secondary;
        color: $color-base;
        transition: background-color 0.3s ease-in-out;
        text-decoration: none;
    }
}
