/**
 * Sidebar
 * component
 */


.sidebar {


  &__title {
    cursor: pointer;
    font-family: $font-primary;
    font-size: 1em;
    font-weight: bold;
    line-height: 1.5;
    margin: 0;
    padding: 1em $gutter;
    position: relative;

    &:after {
      font-family: $font-icon;
      font-weight: 400;
      float: right;
      content: $icon-angle-down;
      transform: rotate(0deg);
      transition: transform .4s ease-in-out;
    }
  }

  &__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height .6s ease-out;
  }

  &__toggle {
    display: none;
  }

  &.-is-open & {
    &__title:after {
      transform: rotate(180deg); 
    }
    &__content {
      max-height: 300vh;
      transition: max-height .4s ease-in-out;
    }
  }

  
  /** Breakpoints **/

  @media screen and (min-width: $breakpoint-sidebar) {
    height: 100%;
    max-width: 30vw;
    overflow-y: auto;
    padding-top: 1em;
    transition: max-width .6s ease-in-out;
    width: 25vw;

    &__toggle {

      display: block;
      font-size: 1em;
      font-weight: 300;
      padding: 0; 
      position: absolute;
      right: 9px;
      text-decoration: none;
      top: 0;
      transform: rotate(-90deg);
      transform-origin: 100% 0 0;
      z-index: 1;

      &:after {
        content: $icon-angle-up;
        display: inline-block;
        font-family: $font-icon;
        line-height: 1.8;
        position: absolute;
        right: 1.2em;
        transform: rotate(0deg);
        transform-origin: 50% 50% 0;
        transition: transform .4s ease-in-out;
      }

      &:hover {
        color: $color-base;
        text-decoration: none;
      }
    }

    &__title {
      cursor: initial;

      &:after {
        content: none;
      }
    }

    &__content {
      max-height: none;
      position: relative;
      z-index: 2;
    }

    &__title,
    &__content {
      opacity: 1;
      transition: opacity .4s ease-in;
    }

    &.-is-collapsed {
      max-width: 1em;
      overflow: hidden;
      transition: max-width .6s ease-in-out;
    }

    &.-is-collapsed & {
      &__title,
      &__content {
        opacity: 0;
        transition: opacity .4s ease-out;
      }

      &__toggle:after {
        transform: rotate(180deg);
      }
    }
  }
}
