/**
 * Fullscreen
 * component
 */

.fullscreen {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
