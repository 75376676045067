/**
 * List button
 * mixin
 */

%list-button {
  background-color: rgba($color-base, .2);
  border-top: 1px solid rgba($color-base, .4);
  color: $color-base;
  display: block;
  font-family: $font-base;
  font-size: 1em;
  font-weight: 400;
  padding: ($gutter / 3 * 2) $gutter;
  text-decoration: none;
  transition: background-color .6s ease-out;

  &:after {
    content: $icon-angle-right;
    font-family: $font-icon;
    float: right;
  }

  &:hover {
    color: $color-base;
    text-decoration: none;
  }
}
