/**
 * Card
 * component
 */

.card {
    position: relative;
    background-color: $color-base;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.15);

    &__bookmark {
        position: absolute;
        top: $gutter / 4;
        right: $gutter / 4;
        font-size: 1.4em;
        padding: 0px;
        margin: 0px;
        background-color: transparent;
        
        .icon-star-o,
        .icon-star {
            padding: 0px;
            margin: 0px;
        }

        &:focus {
            outline: none;
        }
    
    }

    &__header {
        padding: $gutter / 2;
        padding-right: $gutter;
    }

    &__header-link {
        text-decoration: none;
        color: $color-dark;
        overflow: hidden;
        display: block;

        &:hover {
            color: inherit;
            text-decoration: none;
        }

    }

    &__header-heading {
        font-family: $font-primary;
        display: block;
        font-size: 1em;
    }

    &__header-subheading {
        font-family: $font-base;
        display: block;
        font-size: 0.875em;
    }

    &__main {
        padding: 0 ($gutter / 2);

    }

    &__main-inner {
        border-top: 1px solid $color-extralight;
    }

    &__footer {
        padding: $gutter / 2;
        background-color: $color-extralight; 
    }

    &__add {
        width: 100%;
        color: $color-primary;
        text-align: center;
        align-self: center;
        padding: 0px;
        margin: 0px;
        text-decoration: none;
        padding: $gutter / 4;

        .icon-plus {
            display: block;
            color: $color-base;
            background-color: $color-primary;
            width: 2em;
            height: 2em;
            margin: 0 auto;
            line-height: 2em;
            font-size: 1.4em;
            border-radius: 50%;
            transition: background-color .6s ease-out;
        }

        &:hover {
            
            text-decoration: none;
            color: $color-secondary;

            .icon-plus {
                background-color: $color-secondary;
            }

        }

    }

    /** States **/

    /** Modifiers **/

    &.-placeholder {
        background-color: transparent;
        box-shadow: none;
        display: flex;
        vertical-align: center;
        width: 100%;
    }

    /** Breakpoints **/

    @media screen and (min-width: 600px) {

        &__header {
            min-height: 100px;
        }
    

    }

    // @media screen and (min-width: 900px) { 
    
    //     &__header {
    //         min-height: 100px;
    //     }
    
    // }

    // @media screen and (min-width: $breakpoint-header) {
    
    //     &__header {
    //         min-height: 100px;
    //     }
    
    // }

    // @media screen and (min-width: 1100px) { 
    
    //     &__header {
    //         min-height: 100px;
    //     }
    
    // }


}
