/**
 * Header
 * component
 */

.header {
    align-items: center;
    background-color: $color-base;
    display: flex;
    flex-direction: row;
    height: 3.5em;
    // justify-content: space-between;
    padding: 0 ($gutter / 2);

    &__branding,
    &__main-navigation,
    &__sub-navigation {
        position: relative;
        z-index: 100;
    }

    &__branding {
        padding: 0 0 0 0.5em;
        text-align: left;
    }

    &__navigation {
        display: flex;
        text-align: right;
    }

    &__main-navigation {
        background-color: $color-base;
        bottom: 0;
        display: block;
        left: 0;
        max-height: 0;
        opacity: 0;
        overflow-x: hidden;
        overflow-y: auto;
        position: fixed;
        right: 0;
        text-align: left;
        transition: opacity 0.4s ease-in-out, max-height 0.2s ease-out 0.3s;
        top: 3em;
        z-index: 99;
    }

    &.-is-open & {
        &__main-navigation {
            max-height: 100vh;
            opacity: 1;
            transition: opacity 0.6s ease-in-out, max-height 0.3s ease-in-out;
        }
    }

    /** Breakpoints **/

    @media screen and (min-width: $breakpoint-header) {
        align-items: stretch;
        flex-direction: column;
        height: 100%;
        max-width: 25vw;
        overflow-x: hidden;
        padding: 0;
        transition: max-width 0.66s ease-in-out;

        &__branding,
        &__navigation {
            text-align: left;
        }

        &__branding {
            padding: 1em 0 0 1.5em;
        }

        &__navigation {
            align-content: space-between;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: space-between;
            overflow-y: auto;
        }

        &__main-navigation {
            display: block;
            height: auto;
            opacity: 1;
            max-height: none;
            overflow: visible;
            position: static;
            transition: none;
        }

        &.-is-open & {
            &__main-navigation {
                display: block;
                padding: 0;
                position: static;
                text-align: left;
            }
        }

        &.-is-collapsed {
            max-width: 5.6em;
        }

        &.-is-collapsed form input::placeholder {
            opacity: 0;
        }


    }
}
