/**
 * Search field
 * component
 */

.search-field {
  padding: 1em .5em;
  position: relative;

  &__container {
    background-color: $color-base;
    border: 1px solid $color-light;
    border-radius: $radius-mid;
    overflow: hidden;
  }

  &__form {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 18em;
    white-space: nowrap;
  }

  &__label,
  &__input,
  &__submit {
    display: block;
    padding: .5em 0 .4em;
  }

  &__label {
    color: $color-medium;
    text-align: center;
    margin: 0;
    width: 5em;
  }

  &__input {
    appearance: none;
    background-color: transparent;
    border: none;
    flex-grow: 1;
    outline: none;
    padding: .5em 0 .5em .5em;
  }

  &__submit {
    appearance: none;
    background-color: transparent;
    border: none;
    border-radius: 0;
    color: $color-medium;
    right: $gutter;
    transition: color .6s ease-out;
    width: 2em;

    &:hover,
    &:focus {
      color: $color-secondary;
      transition: color .3s ease-in-out;
    }
  }
}
