/**
 * Table
 * component
 *
 * THIS COMPONENT DEPENDS ON BOOTSTRAP 3.3.7 TABLE CLASS
 */


.table {
    border-bottom: 1px solid $color-extralight;
    color: $color-medium;
    font-family: $font-base;
    font-size: 0.875em;

    a {
        color: inherit;

        &,
        &:hover,
        &:focus {
        text-decoration: inherit;
        }
    }

    thead {
        tr {
            th, td {
                border-bottom: 0px;
                border-color: $color-extralight;
                font-weight: bold;
                padding: ($gutter / 4) 4px;
                vertical-align: middle;
            }
        }
    }

    tbody {
        tr {
            th, td {
                border-color: $color-extralight;
                border-bottom: 0px;
                padding: ($gutter / 4) 4px;
                vertical-align: top;
            }
        }
    }

    tfoot {
        tr {
            th, td {
                border-color: $color-extralight;
                border-bottom: 0px;
                padding: ($gutter / 4) 0;
                vertical-align: middle;
            }
        }
    }

    &__actions,
    &__noWrap {
      white-space: nowrap;
    }

    &__title {
      min-width: 200px;
    }

    &__bookmark {
        font-size: 1.4em;
        padding: 0px;
        margin: 0px;
        background-color: transparent;

        &:focus {
            outline: none;
        }

    }

    // Create columns 1 - 100 (steps of 5%)
    // Example: .table__column-25 (creates a column, width set to 25%)
    $i: 0;
    @while $i <= 100 {
        &__column-#{$i} {
            width: ($i * 1%);
        }
        $i: ($i + 5);
    }

    /** Modifiers **/

    /** States **/

    /** Breakpoints **/


}
