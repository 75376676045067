/**
 * Logo
 * component
 */

.logo {
    display: block;
    width: 0;
    height: 0;
    padding: ($gutter * .9) 0 0 ($gutter * 1.5);
    margin: 0;
    overflow: hidden;
    background-image: url('../../../images/alii-logo.jpg');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: auto 75%;
}
