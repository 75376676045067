/**
 * Icons
 * base
 */

.icon-asterisk {
    &:before {
        content: $icon-asterisk;
    }
}
.icon-plus {
    &:before {
        content: $icon-plus;
    }
}
.icon-question {
    &:before {
        content: $icon-question;
    }
}
.icon-minus {
    &:before {
        content: $icon-minus;
    }
}
.icon-glass {
    &:before {
        content: $icon-glass;
    }
}
.icon-music {
    &:before {
        content: $icon-music;
    }
}
.icon-search {
    &:before {
        content: $icon-search;
    }
}
.icon-envelope-o {
    &:before {
        content: $icon-envelope-o;
    }
}
.icon-heart {
    &:before {
        content: $icon-heart;
    }
}
.icon-star {
    &:before {
        content: $icon-star;
    }
}
.icon-star-o {
    &:before {
        content: $icon-star-o;
    }
}
.icon-user {
    &:before {
        content: $icon-user;
    }
}
.icon-film {
    &:before {
        content: $icon-film;
    }
}
.icon-th-large {
    &:before {
        content: $icon-th-large;
    }
}
.icon-th {
    &:before {
        content: $icon-th;
    }
}
.icon-th-list {
    &:before {
        content: $icon-th-list;
    }
}
.icon-check {
    &:before {
        content: $icon-check;
    }
}
.icon-close {
    &:before {
        content: $icon-close;
    }
}
.icon-remove {
    &:before {
        content: $icon-remove;
    }
}
.icon-times {
    &:before {
        content: $icon-times;
    }
}
.icon-search-plus {
    &:before {
        content: $icon-search-plus;
    }
}
.icon-search-minus {
    &:before {
        content: $icon-search-minus;
    }
}
.icon-power-off {
    &:before {
        content: $icon-power-off;
    }
}
.icon-signal {
    &:before {
        content: $icon-signal;
    }
}
.icon-cog {
    &:before {
        content: $icon-cog;
    }
}
.icon-gear {
    &:before {
        content: $icon-gear;
    }
}
.icon-trash-o {
    &:before {
        content: $icon-trash-o;
    }
}
.icon-home {
    &:before {
        content: $icon-home;
    }
}
.icon-file-o {
    &:before {
        content: $icon-file-o;
    }
}
.icon-clock-o {
    &:before {
        content: $icon-clock-o;
    }
}
.icon-road {
    &:before {
        content: $icon-road;
    }
}
.icon-download {
    &:before {
        content: $icon-download;
    }
}
.icon-arrow-circle-o-down {
    &:before {
        content: $icon-arrow-circle-o-down;
    }
}
.icon-arrow-circle-o-up {
    &:before {
        content: $icon-arrow-circle-o-up;
    }
}
.icon-inbox {
    &:before {
        content: $icon-inbox;
    }
}
.icon-play-circle-o {
    &:before {
        content: $icon-play-circle-o;
    }
}
.icon-repeat {
    &:before {
        content: $icon-repeat;
    }
}
.icon-rotate-right {
    &:before {
        content: $icon-rotate-right;
    }
}
.icon-refresh {
    &:before {
        content: $icon-refresh;
    }
}
.icon-list-alt {
    &:before {
        content: $icon-list-alt;
    }
}
.icon-lock {
    &:before {
        content: $icon-lock;
    }
}
.icon-flag {
    &:before {
        content: $icon-flag;
    }
}
.icon-headphones {
    &:before {
        content: $icon-headphones;
    }
}
.icon-volume-off {
    &:before {
        content: $icon-volume-off;
    }
}
.icon-volume-down {
    &:before {
        content: $icon-volume-down;
    }
}
.icon-volume-up {
    &:before {
        content: $icon-volume-up;
    }
}
.icon-qrcode {
    &:before {
        content: $icon-qrcode;
    }
}
.icon-barcode {
    &:before {
        content: $icon-barcode;
    }
}
.icon-tag {
    &:before {
        content: $icon-tag;
    }
}
.icon-tags {
    &:before {
        content: $icon-tags;
    }
}
.icon-book {
    &:before {
        content: $icon-book;
    }
}
.icon-bookmark {
    &:before {
        content: $icon-bookmark;
    }
}
.icon-print {
    &:before {
        content: $icon-print;
    }
}
.icon-camera {
    &:before {
        content: $icon-camera;
    }
}
.icon-font {
    &:before {
        content: $icon-font;
    }
}
.icon-bold {
    &:before {
        content: $icon-bold;
    }
}
.icon-italic {
    &:before {
        content: $icon-italic;
    }
}
.icon-text-height {
    &:before {
        content: $icon-text-height;
    }
}
.icon-text-width {
    &:before {
        content: $icon-text-width;
    }
}
.icon-align-left {
    &:before {
        content: $icon-align-left;
    }
}
.icon-align-center {
    &:before {
        content: $icon-align-center;
    }
}
.icon-align-right {
    &:before {
        content: $icon-align-right;
    }
}
.icon-align-justify {
    &:before {
        content: $icon-align-justify;
    }
}
.icon-list {
    &:before {
        content: $icon-list;
    }
}
.icon-dedent {
    &:before {
        content: $icon-dedent;
    }
}
.icon-outdent {
    &:before {
        content: $icon-outdent;
    }
}
.icon-indent {
    &:before {
        content: $icon-indent;
    }
}
.icon-video-camera {
    &:before {
        content: $icon-video-camera;
    }
}
.icon-image {
    &:before {
        content: $icon-image;
    }
}
.icon-photo {
    &:before {
        content: $icon-photo;
    }
}
.icon-picture-o {
    &:before {
        content: $icon-picture-o;
    }
}
.icon-pencil {
    &:before {
        content: $icon-pencil;
    }
}
.icon-map-marker {
    &:before {
        content: $icon-map-marker;
    }
}
.icon-adjust {
    &:before {
        content: $icon-adjust;
    }
}
.icon-tint {
    &:before {
        content: $icon-tint;
    }
}
.icon-edit {
    &:before {
        content: $icon-edit;
    }
}
.icon-pencil-square-o {
    &:before {
        content: $icon-pencil-square-o;
    }
}
.icon-share-square-o {
    &:before {
        content: $icon-share-square-o;
    }
}
.icon-check-square-o {
    &:before {
        content: $icon-check-square-o;
    }
}
.icon-arrows {
    &:before {
        content: $icon-arrows;
    }
}
.icon-step-backward {
    &:before {
        content: $icon-step-backward;
    }
}
.icon-fast-backward {
    &:before {
        content: $icon-fast-backward;
    }
}
.icon-backward {
    &:before {
        content: $icon-backward;
    }
}
.icon-play {
    &:before {
        content: $icon-play;
    }
}
.icon-pause {
    &:before {
        content: $icon-pause;
    }
}
.icon-stop {
    &:before {
        content: $icon-stop;
    }
}
.icon-forward {
    &:before {
        content: $icon-forward;
    }
}
.icon-fast-forward {
    &:before {
        content: $icon-fast-forward;
    }
}
.icon-step-forward {
    &:before {
        content: $icon-step-forward;
    }
}
.icon-eject {
    &:before {
        content: $icon-eject;
    }
}
.icon-chevron-left {
    &:before {
        content: $icon-chevron-left;
    }
}
.icon-chevron-right {
    &:before {
        content: $icon-chevron-right;
    }
}
.icon-plus-circle {
    &:before {
        content: $icon-plus-circle;
    }
}
.icon-minus-circle {
    &:before {
        content: $icon-minus-circle;
    }
}
.icon-times-circle {
    &:before {
        content: $icon-times-circle;
    }
}
.icon-check-circle {
    &:before {
        content: $icon-check-circle;
    }
}
.icon-question-circle {
    &:before {
        content: $icon-question-circle;
    }
}
.icon-info-circle {
    &:before {
        content: $icon-info-circle;
    }
}
.icon-crosshairs {
    &:before {
        content: $icon-crosshairs;
    }
}
.icon-times-circle-o {
    &:before {
        content: $icon-times-circle-o;
    }
}
.icon-check-circle-o {
    &:before {
        content: $icon-check-circle-o;
    }
}
.icon-ban {
    &:before {
        content: $icon-ban;
    }
}
.icon-arrow-left {
    &:before {
        content: $icon-arrow-left;
    }
}
.icon-arrow-right {
    &:before {
        content: $icon-arrow-right;
    }
}
.icon-arrow-up {
    &:before {
        content: $icon-arrow-up;
    }
}
.icon-arrow-down {
    &:before {
        content: $icon-arrow-down;
    }
}
.icon-mail-forward {
    &:before {
        content: $icon-mail-forward;
    }
}
.icon-share {
    &:before {
        content: $icon-share;
    }
}
.icon-expand {
    &:before {
        content: $icon-expand;
    }
}
.icon-compress {
    &:before {
        content: $icon-compress;
    }
}
.icon-exclamation-circle {
    &:before {
        content: $icon-exclamation-circle;
    }
}
.icon-gift {
    &:before {
        content: $icon-gift;
    }
}
.icon-leaf {
    &:before {
        content: $icon-leaf;
    }
}
.icon-fire {
    &:before {
        content: $icon-fire;
    }
}
.icon-eye {
    &:before {
        content: $icon-eye;
    }
}
.icon-eye-slash {
    &:before {
        content: $icon-eye-slash;
    }
}
.icon-exclamation-triangle {
    &:before {
        content: $icon-exclamation-triangle;
    }
}
.icon-warning {
    &:before {
        content: $icon-warning;
    }
}
.icon-plane {
    &:before {
        content: $icon-plane;
    }
}
.icon-calendar {
    &:before {
        content: $icon-calendar;
    }
}
.icon-random {
    &:before {
        content: $icon-random;
    }
}
.icon-comment {
    &:before {
        content: $icon-comment;
    }
}
.icon-magnet {
    &:before {
        content: $icon-magnet;
    }
}
.icon-chevron-up {
    &:before {
        content: $icon-chevron-up;
    }
}
.icon-chevron-down {
    &:before {
        content: $icon-chevron-down;
    }
}
.icon-retweet {
    &:before {
        content: $icon-retweet;
    }
}
.icon-shopping-cart {
    &:before {
        content: $icon-shopping-cart;
    }
}
.icon-folder {
    &:before {
        content: $icon-folder;
    }
}
.icon-folder-open {
    &:before {
        content: $icon-folder-open;
    }
}
.icon-arrows-v {
    &:before {
        content: $icon-arrows-v;
    }
}
.icon-arrows-h {
    &:before {
        content: $icon-arrows-h;
    }
}
.icon-bar-chart {
    &:before {
        content: $icon-bar-chart;
    }
}
.icon-bar-chart-o {
    &:before {
        content: $icon-bar-chart-o;
    }
}
.icon-twitter-square {
    &:before {
        content: $icon-twitter-square;
    }
}
.icon-facebook-square {
    &:before {
        content: $icon-facebook-square;
    }
}
.icon-camera-retro {
    &:before {
        content: $icon-camera-retro;
    }
}
.icon-key {
    &:before {
        content: $icon-key;
    }
}
.icon-cogs {
    &:before {
        content: $icon-cogs;
    }
}
.icon-gears {
    &:before {
        content: $icon-gears;
    }
}
.icon-comments {
    &:before {
        content: $icon-comments;
    }
}
.icon-thumbs-o-up {
    &:before {
        content: $icon-thumbs-o-up;
    }
}
.icon-thumbs-o-down {
    &:before {
        content: $icon-thumbs-o-down;
    }
}
.icon-star-half {
    &:before {
        content: $icon-star-half;
    }
}
.icon-heart-o {
    &:before {
        content: $icon-heart-o;
    }
}
.icon-sign-out {
    &:before {
        content: $icon-sign-out;
    }
}
.icon-linkedin-square {
    &:before {
        content: $icon-linkedin-square;
    }
}
.icon-thumb-tack {
    &:before {
        content: $icon-thumb-tack;
    }
}
.icon-external-link {
    &:before {
        content: $icon-external-link;
    }
}
.icon-sign-in {
    &:before {
        content: $icon-sign-in;
    }
}
.icon-trophy {
    &:before {
        content: $icon-trophy;
    }
}
.icon-github-square {
    &:before {
        content: $icon-github-square;
    }
}
.icon-upload {
    &:before {
        content: $icon-upload;
    }
}
.icon-lemon-o {
    &:before {
        content: $icon-lemon-o;
    }
}
.icon-phone {
    &:before {
        content: $icon-phone;
    }
}
.icon-square-o {
    &:before {
        content: $icon-square-o;
    }
}
.icon-bookmark-o {
    &:before {
        content: $icon-bookmark-o;
    }
}
.icon-phone-square {
    &:before {
        content: $icon-phone-square;
    }
}
.icon-twitter {
    &:before {
        content: $icon-twitter;
    }
}
.icon-facebook {
    &:before {
        content: $icon-facebook;
    }
}
.icon-facebook-f {
    &:before {
        content: $icon-facebook-f;
    }
}
.icon-github {
    &:before {
        content: $icon-github;
    }
}
.icon-unlock {
    &:before {
        content: $icon-unlock;
    }
}
.icon-credit-card {
    &:before {
        content: $icon-credit-card;
    }
}
.icon-feed {
    &:before {
        content: $icon-feed;
    }
}
.icon-rss {
    &:before {
        content: $icon-rss;
    }
}
.icon-hdd-o {
    &:before {
        content: $icon-hdd-o;
    }
}
.icon-bullhorn {
    &:before {
        content: $icon-bullhorn;
    }
}
.icon-bell-o {
    &:before {
        content: $icon-bell-o;
    }
}
.icon-certificate {
    &:before {
        content: $icon-certificate;
    }
}
.icon-hand-o-right {
    &:before {
        content: $icon-hand-o-right;
    }
}
.icon-hand-o-left {
    &:before {
        content: $icon-hand-o-left;
    }
}
.icon-hand-o-up {
    &:before {
        content: $icon-hand-o-up;
    }
}
.icon-hand-o-down {
    &:before {
        content: $icon-hand-o-down;
    }
}
.icon-arrow-circle-left {
    &:before {
        content: $icon-arrow-circle-left;
    }
}
.icon-arrow-circle-right {
    &:before {
        content: $icon-arrow-circle-right;
    }
}
.icon-arrow-circle-up {
    &:before {
        content: $icon-arrow-circle-up;
    }
}
.icon-arrow-circle-down {
    &:before {
        content: $icon-arrow-circle-down;
    }
}
.icon-globe {
    &:before {
        content: $icon-globe;
    }
}
.icon-wrench {
    &:before {
        content: $icon-wrench;
    }
}
.icon-tasks {
    &:before {
        content: $icon-tasks;
    }
}
.icon-filter {
    &:before {
        content: $icon-filter;
    }
}
.icon-briefcase {
    &:before {
        content: $icon-briefcase;
    }
}
.icon-arrows-alt {
    &:before {
        content: $icon-arrows-alt;
    }
}
.icon-group {
    &:before {
        content: $icon-group;
    }
}
.icon-users {
    &:before {
        content: $icon-users;
    }
}
.icon-chain {
    &:before {
        content: $icon-chain;
    }
}
.icon-link {
    &:before {
        content: $icon-link;
    }
}
.icon-cloud {
    &:before {
        content: $icon-cloud;
    }
}
.icon-flask {
    &:before {
        content: $icon-flask;
    }
}
.icon-cut {
    &:before {
        content: $icon-cut;
    }
}
.icon-scissors {
    &:before {
        content: $icon-scissors;
    }
}
.icon-copy {
    &:before {
        content: $icon-copy;
    }
}
.icon-files-o {
    &:before {
        content: $icon-files-o;
    }
}
.icon-paperclip {
    &:before {
        content: $icon-paperclip;
    }
}
.icon-floppy-o {
    &:before {
        content: $icon-floppy-o;
    }
}
.icon-save {
    &:before {
        content: $icon-save;
    }
}
.icon-square {
    &:before {
        content: $icon-square;
    }
}
.icon-bars {
    &:before {
        content: $icon-bars;
    }
}
.icon-navicon {
    &:before {
        content: $icon-navicon;
    }
}
.icon-reorder {
    &:before {
        content: $icon-reorder;
    }
}
.icon-list-ul {
    &:before {
        content: $icon-list-ul;
    }
}
.icon-list-ol {
    &:before {
        content: $icon-list-ol;
    }
}
.icon-strikethrough {
    &:before {
        content: $icon-strikethrough;
    }
}
.icon-underline {
    &:before {
        content: $icon-underline;
    }
}
.icon-table {
    &:before {
        content: $icon-table;
    }
}
.icon-magic {
    &:before {
        content: $icon-magic;
    }
}
.icon-truck {
    &:before {
        content: $icon-truck;
    }
}
.icon-pinterest {
    &:before {
        content: $icon-pinterest;
    }
}
.icon-pinterest-square {
    &:before {
        content: $icon-pinterest-square;
    }
}
.icon-google-plus-square {
    &:before {
        content: $icon-google-plus-square;
    }
}
.icon-google-plus {
    &:before {
        content: $icon-google-plus;
    }
}
.icon-money {
    &:before {
        content: $icon-money;
    }
}
.icon-caret-down {
    &:before {
        content: $icon-caret-down;
    }
}
.icon-caret-up {
    &:before {
        content: $icon-caret-up;
    }
}
.icon-caret-left {
    &:before {
        content: $icon-caret-left;
    }
}
.icon-caret-right {
    &:before {
        content: $icon-caret-right;
    }
}
.icon-columns {
    &:before {
        content: $icon-columns;
    }
}
.icon-sort {
    &:before {
        content: $icon-sort;
    }
}
.icon-unsorted {
    &:before {
        content: $icon-unsorted;
    }
}
.icon-sort-desc {
    &:before {
        content: $icon-sort-desc;
    }
}
.icon-sort-down {
    &:before {
        content: $icon-sort-down;
    }
}
.icon-sort-asc {
    &:before {
        content: $icon-sort-asc;
    }
}
.icon-sort-up {
    &:before {
        content: $icon-sort-up;
    }
}
.icon-envelope {
    &:before {
        content: $icon-envelope;
    }
}
.icon-linkedin {
    &:before {
        content: $icon-linkedin;
    }
}
.icon-rotate-left {
    &:before {
        content: $icon-rotate-left;
    }
}
.icon-undo {
    &:before {
        content: $icon-undo;
    }
}
.icon-gavel {
    &:before {
        content: $icon-gavel;
    }
}
.icon-legal {
    &:before {
        content: $icon-legal;
    }
}
.icon-dashboard {
    &:before {
        content: $icon-dashboard;
    }
}
.icon-tachometer {
    &:before {
        content: $icon-tachometer;
    }
}
.icon-comment-o {
    &:before {
        content: $icon-comment-o;
    }
}
.icon-comments-o {
    &:before {
        content: $icon-comments-o;
    }
}
.icon-bolt {
    &:before {
        content: $icon-bolt;
    }
}
.icon-flash {
    &:before {
        content: $icon-flash;
    }
}
.icon-sitemap {
    &:before {
        content: $icon-sitemap;
    }
}
.icon-umbrella {
    &:before {
        content: $icon-umbrella;
    }
}
.icon-clipboard {
    &:before {
        content: $icon-clipboard;
    }
}
.icon-paste {
    &:before {
        content: $icon-paste;
    }
}
.icon-lightbulb-o {
    &:before {
        content: $icon-lightbulb-o;
    }
}
.icon-exchange {
    &:before {
        content: $icon-exchange;
    }
}
.icon-cloud-download {
    &:before {
        content: $icon-cloud-download;
    }
}
.icon-cloud-upload {
    &:before {
        content: $icon-cloud-upload;
    }
}
.icon-user-md {
    &:before {
        content: $icon-user-md;
    }
}
.icon-stethoscope {
    &:before {
        content: $icon-stethoscope;
    }
}
.icon-suitcase {
    &:before {
        content: $icon-suitcase;
    }
}
.icon-bell {
    &:before {
        content: $icon-bell;
    }
}
.icon-coffee {
    &:before {
        content: $icon-coffee;
    }
}
.icon-cutlery {
    &:before {
        content: $icon-cutlery;
    }
}
.icon-file-text-o {
    &:before {
        content: $icon-file-text-o;
    }
}
.icon-building-o {
    &:before {
        content: $icon-building-o;
    }
}
.icon-hospital-o {
    &:before {
        content: $icon-hospital-o;
    }
}
.icon-ambulance {
    &:before {
        content: $icon-ambulance;
    }
}
.icon-medkit {
    &:before {
        content: $icon-medkit;
    }
}
.icon-fighter-jet {
    &:before {
        content: $icon-fighter-jet;
    }
}
.icon-beer {
    &:before {
        content: $icon-beer;
    }
}
.icon-h-square {
    &:before {
        content: $icon-h-square;
    }
}
.icon-plus-square {
    &:before {
        content: $icon-plus-square;
    }
}
.icon-angle-double-left {
    &:before {
        content: $icon-angle-double-left;
    }
}
.icon-angle-double-right {
    &:before {
        content: $icon-angle-double-right;
    }
}
.icon-angle-double-up {
    &:before {
        content: $icon-angle-double-up;
    }
}
.icon-angle-double-down {
    &:before {
        content: $icon-angle-double-down;
    }
}
.icon-angle-left {
    &:before {
        content: $icon-angle-left;
    }
}
.icon-angle-right {
    &:before {
        content: $icon-angle-right;
    }
}
.icon-angle-up {
    &:before {
        content: $icon-angle-up;
    }
}
.icon-angle-down {
    &:before {
        content: $icon-angle-down;
    }
}
.icon-desktop {
    &:before {
        content: $icon-desktop;
    }
}
.icon-laptop {
    &:before {
        content: $icon-laptop;
    }
}
.icon-tablet {
    &:before {
        content: $icon-tablet;
    }
}
.icon-mobile {
    &:before {
        content: $icon-mobile;
    }
}
.icon-mobile-phone {
    &:before {
        content: $icon-mobile-phone;
    }
}
.icon-circle-o {
    &:before {
        content: $icon-circle-o;
    }
}
.icon-quote-left {
    &:before {
        content: $icon-quote-left;
    }
}
.icon-quote-right {
    &:before {
        content: $icon-quote-right;
    }
}
.icon-spinner {
    &:before {
        content: $icon-spinner;
    }
}
.icon-circle {
    &:before {
        content: $icon-circle;
    }
}
.icon-mail-reply {
    &:before {
        content: $icon-mail-reply;
    }
}
.icon-reply {
    &:before {
        content: $icon-reply;
    }
}
.icon-github-alt {
    &:before {
        content: $icon-github-alt;
    }
}
.icon-folder-o {
    &:before {
        content: $icon-folder-o;
    }
}
.icon-folder-open-o {
    &:before {
        content: $icon-folder-open-o;
    }
}
.icon-smile-o {
    &:before {
        content: $icon-smile-o;
    }
}
.icon-frown-o {
    &:before {
        content: $icon-frown-o;
    }
}
.icon-meh-o {
    &:before {
        content: $icon-meh-o;
    }
}
.icon-gamepad {
    &:before {
        content: $icon-gamepad;
    }
}
.icon-keyboard-o {
    &:before {
        content: $icon-keyboard-o;
    }
}
.icon-flag-o {
    &:before {
        content: $icon-flag-o;
    }
}
.icon-flag-checkered {
    &:before {
        content: $icon-flag-checkered;
    }
}
.icon-terminal {
    &:before {
        content: $icon-terminal;
    }
}
.icon-code {
    &:before {
        content: $icon-code;
    }
}
.icon-mail-reply-all {
    &:before {
        content: $icon-mail-reply-all;
    }
}
.icon-reply-all {
    &:before {
        content: $icon-reply-all;
    }
}
.icon-star-half-empty {
    &:before {
        content: $icon-star-half-empty;
    }
}
.icon-star-half-full {
    &:before {
        content: $icon-star-half-full;
    }
}
.icon-star-half-o {
    &:before {
        content: $icon-star-half-o;
    }
}
.icon-location-arrow {
    &:before {
        content: $icon-location-arrow;
    }
}
.icon-crop {
    &:before {
        content: $icon-crop;
    }
}
.icon-code-fork {
    &:before {
        content: $icon-code-fork;
    }
}
.icon-chain-broken {
    &:before {
        content: $icon-chain-broken;
    }
}
.icon-unlink {
    &:before {
        content: $icon-unlink;
    }
}
.icon-info {
    &:before {
        content: $icon-info;
    }
}
.icon-exclamation {
    &:before {
        content: $icon-exclamation;
    }
}
.icon-superscript {
    &:before {
        content: $icon-superscript;
    }
}
.icon-subscript {
    &:before {
        content: $icon-subscript;
    }
}
.icon-eraser {
    &:before {
        content: $icon-eraser;
    }
}
.icon-puzzle-piece {
    &:before {
        content: $icon-puzzle-piece;
    }
}
.icon-microphone {
    &:before {
        content: $icon-microphone;
    }
}
.icon-microphone-slash {
    &:before {
        content: $icon-microphone-slash;
    }
}
.icon-shield {
    &:before {
        content: $icon-shield;
    }
}
.icon-calendar-o {
    &:before {
        content: $icon-calendar-o;
    }
}
.icon-fire-extinguisher {
    &:before {
        content: $icon-fire-extinguisher;
    }
}
.icon-rocket {
    &:before {
        content: $icon-rocket;
    }
}
.icon-maxcdn {
    &:before {
        content: $icon-maxcdn;
    }
}
.icon-chevron-circle-left {
    &:before {
        content: $icon-chevron-circle-left;
    }
}
.icon-chevron-circle-right {
    &:before {
        content: $icon-chevron-circle-right;
    }
}
.icon-chevron-circle-up {
    &:before {
        content: $icon-chevron-circle-up;
    }
}
.icon-chevron-circle-down {
    &:before {
        content: $icon-chevron-circle-down;
    }
}
.icon-html5 {
    &:before {
        content: $icon-html5;
    }
}
.icon-css3 {
    &:before {
        content: $icon-css3;
    }
}
.icon-anchor {
    &:before {
        content: $icon-anchor;
    }
}
.icon-unlock-alt {
    &:before {
        content: $icon-unlock-alt;
    }
}
.icon-bullseye {
    &:before {
        content: $icon-bullseye;
    }
}
.icon-ellipsis-h {
    &:before {
        content: $icon-ellipsis-h;
    }
}
.icon-ellipsis-v {
    &:before {
        content: $icon-ellipsis-v;
    }
}
.icon-rss-square {
    &:before {
        content: $icon-rss-square;
    }
}
.icon-play-circle {
    &:before {
        content: $icon-play-circle;
    }
}
.icon-ticket {
    &:before {
        content: $icon-ticket;
    }
}
.icon-minus-square {
    &:before {
        content: $icon-minus-square;
    }
}
.icon-minus-square-o {
    &:before {
        content: $icon-minus-square-o;
    }
}
.icon-level-up {
    &:before {
        content: $icon-level-up;
    }
}
.icon-level-down {
    &:before {
        content: $icon-level-down;
    }
}
.icon-check-square {
    &:before {
        content: $icon-check-square;
    }
}
.icon-pencil-square {
    &:before {
        content: $icon-pencil-square;
    }
}
.icon-external-link-square {
    &:before {
        content: $icon-external-link-square;
    }
}
.icon-share-square {
    &:before {
        content: $icon-share-square;
    }
}
.icon-compass {
    &:before {
        content: $icon-compass;
    }
}
.icon-caret-square-o-down {
    &:before {
        content: $icon-caret-square-o-down;
    }
}
.icon-toggle-down {
    &:before {
        content: $icon-toggle-down;
    }
}
.icon-caret-square-o-up {
    &:before {
        content: $icon-caret-square-o-up;
    }
}
.icon-toggle-up {
    &:before {
        content: $icon-toggle-up;
    }
}
.icon-caret-square-o-right {
    &:before {
        content: $icon-caret-square-o-right;
    }
}
.icon-toggle-right {
    &:before {
        content: $icon-toggle-right;
    }
}
.icon-eur {
    &:before {
        content: $icon-eur;
    }
}
.icon-euro {
    &:before {
        content: $icon-euro;
    }
}
.icon-gbp {
    &:before {
        content: $icon-gbp;
    }
}
.icon-dollar {
    &:before {
        content: $icon-dollar;
    }
}
.icon-usd {
    &:before {
        content: $icon-usd;
    }
}
.icon-inr {
    &:before {
        content: $icon-inr;
    }
}
.icon-rupee {
    &:before {
        content: $icon-rupee;
    }
}
.icon-cny {
    &:before {
        content: $icon-cny;
    }
}
.icon-jpy {
    &:before {
        content: $icon-jpy;
    }
}
.icon-rmb {
    &:before {
        content: $icon-rmb;
    }
}
.icon-yen {
    &:before {
        content: $icon-yen;
    }
}
.icon-rouble {
    &:before {
        content: $icon-rouble;
    }
}
.icon-rub {
    &:before {
        content: $icon-rub;
    }
}
.icon-ruble {
    &:before {
        content: $icon-ruble;
    }
}
.icon-krw {
    &:before {
        content: $icon-krw;
    }
}
.icon-won {
    &:before {
        content: $icon-won;
    }
}
.icon-bitcoin {
    &:before {
        content: $icon-bitcoin;
    }
}
.icon-btc {
    &:before {
        content: $icon-btc;
    }
}
.icon-file {
    &:before {
        content: $icon-file;
    }
}
.icon-file-text {
    &:before {
        content: $icon-file-text;
    }
}
.icon-sort-alpha-asc {
    &:before {
        content: $icon-sort-alpha-asc;
    }
}
.icon-sort-alpha-desc {
    &:before {
        content: $icon-sort-alpha-desc;
    }
}
.icon-sort-amount-asc {
    &:before {
        content: $icon-sort-amount-asc;
    }
}
.icon-sort-amount-desc {
    &:before {
        content: $icon-sort-amount-desc;
    }
}
.icon-sort-numeric-asc {
    &:before {
        content: $icon-sort-numeric-asc;
    }
}
.icon-sort-numeric-desc {
    &:before {
        content: $icon-sort-numeric-desc;
    }
}
.icon-thumbs-up {
    &:before {
        content: $icon-thumbs-up;
    }
}
.icon-thumbs-down {
    &:before {
        content: $icon-thumbs-down;
    }
}
.icon-youtube-square {
    &:before {
        content: $icon-youtube-square;
    }
}
.icon-youtube {
    &:before {
        content: $icon-youtube;
    }
}
.icon-xing {
    &:before {
        content: $icon-xing;
    }
}
.icon-xing-square {
    &:before {
        content: $icon-xing-square;
    }
}
.icon-youtube-play {
    &:before {
        content: $icon-youtube-play;
    }
}
.icon-dropbox {
    &:before {
        content: $icon-dropbox;
    }
}
.icon-stack-overflow {
    &:before {
        content: $icon-stack-overflow;
    }
}
.icon-instagram {
    &:before {
        content: $icon-instagram;
    }
}
.icon-flickr {
    &:before {
        content: $icon-flickr;
    }
}
.icon-adn {
    &:before {
        content: $icon-adn;
    }
}
.icon-bitbucket {
    &:before {
        content: $icon-bitbucket;
    }
}
.icon-bitbucket-square {
    &:before {
        content: $icon-bitbucket-square;
    }
}
.icon-tumblr {
    &:before {
        content: $icon-tumblr;
    }
}
.icon-tumblr-square {
    &:before {
        content: $icon-tumblr-square;
    }
}
.icon-long-arrow-down {
    &:before {
        content: $icon-long-arrow-down;
    }
}
.icon-long-arrow-up {
    &:before {
        content: $icon-long-arrow-up;
    }
}
.icon-long-arrow-left {
    &:before {
        content: $icon-long-arrow-left;
    }
}
.icon-long-arrow-right {
    &:before {
        content: $icon-long-arrow-right;
    }
}
.icon-apple {
    &:before {
        content: $icon-apple;
    }
}
.icon-windows {
    &:before {
        content: $icon-windows;
    }
}
.icon-android {
    &:before {
        content: $icon-android;
    }
}
.icon-linux {
    &:before {
        content: $icon-linux;
    }
}
.icon-dribbble {
    &:before {
        content: $icon-dribbble;
    }
}
.icon-skype {
    &:before {
        content: $icon-skype;
    }
}
.icon-foursquare {
    &:before {
        content: $icon-foursquare;
    }
}
.icon-trello {
    &:before {
        content: $icon-trello;
    }
}
.icon-female {
    &:before {
        content: $icon-female;
    }
}
.icon-male {
    &:before {
        content: $icon-male;
    }
}
.icon-gittip {
    &:before {
        content: $icon-gittip;
    }
}
.icon-gratipay {
    &:before {
        content: $icon-gratipay;
    }
}
.icon-sun-o {
    &:before {
        content: $icon-sun-o;
    }
}
.icon-moon-o {
    &:before {
        content: $icon-moon-o;
    }
}
.icon-archive {
    &:before {
        content: $icon-archive;
    }
}
.icon-bug {
    &:before {
        content: $icon-bug;
    }
}
.icon-vk {
    &:before {
        content: $icon-vk;
    }
}
.icon-weibo {
    &:before {
        content: $icon-weibo;
    }
}
.icon-renren {
    &:before {
        content: $icon-renren;
    }
}
.icon-pagelines {
    &:before {
        content: $icon-pagelines;
    }
}
.icon-stack-exchange {
    &:before {
        content: $icon-stack-exchange;
    }
}
.icon-arrow-circle-o-right {
    &:before {
        content: $icon-arrow-circle-o-right;
    }
}
.icon-arrow-circle-o-left {
    &:before {
        content: $icon-arrow-circle-o-left;
    }
}
.icon-caret-square-o-left {
    &:before {
        content: $icon-caret-square-o-left;
    }
}
.icon-toggle-left {
    &:before {
        content: $icon-toggle-left;
    }
}
.icon-dot-circle-o {
    &:before {
        content: $icon-dot-circle-o;
    }
}
.icon-wheelchair {
    &:before {
        content: $icon-wheelchair;
    }
}
.icon-vimeo-square {
    &:before {
        content: $icon-vimeo-square;
    }
}
.icon-try {
    &:before {
        content: $icon-try;
    }
}
.icon-turkish-lira {
    &:before {
        content: $icon-turkish-lira;
    }
}
.icon-plus-square-o {
    &:before {
        content: $icon-plus-square-o;
    }
}
.icon-space-shuttle {
    &:before {
        content: $icon-space-shuttle;
    }
}
.icon-slack {
    &:before {
        content: $icon-slack;
    }
}
.icon-envelope-square {
    &:before {
        content: $icon-envelope-square;
    }
}
.icon-wordpress {
    &:before {
        content: $icon-wordpress;
    }
}
.icon-openid {
    &:before {
        content: $icon-openid;
    }
}
.icon-bank {
    &:before {
        content: $icon-bank;
    }
}
.icon-institution {
    &:before {
        content: $icon-institution;
    }
}
.icon-university {
    &:before {
        content: $icon-university;
    }
}
.icon-graduation-cap {
    &:before {
        content: $icon-graduation-cap;
    }
}
.icon-mortar-board {
    &:before {
        content: $icon-mortar-board;
    }
}
.icon-yahoo {
    &:before {
        content: $icon-yahoo;
    }
}
.icon-google {
    &:before {
        content: $icon-google;
    }
}
.icon-reddit {
    &:before {
        content: $icon-reddit;
    }
}
.icon-reddit-square {
    &:before {
        content: $icon-reddit-square;
    }
}
.icon-stumbleupon-circle {
    &:before {
        content: $icon-stumbleupon-circle;
    }
}
.icon-stumbleupon {
    &:before {
        content: $icon-stumbleupon;
    }
}
.icon-delicious {
    &:before {
        content: $icon-delicious;
    }
}
.icon-digg {
    &:before {
        content: $icon-digg;
    }
}
.icon-pied-piper-pp {
    &:before {
        content: $icon-pied-piper-pp;
    }
}
.icon-pied-piper-alt {
    &:before {
        content: $icon-pied-piper-alt;
    }
}
.icon-drupal {
    &:before {
        content: $icon-drupal;
    }
}
.icon-joomla {
    &:before {
        content: $icon-joomla;
    }
}
.icon-language {
    &:before {
        content: $icon-language;
    }
}
.icon-fax {
    &:before {
        content: $icon-fax;
    }
}
.icon-building {
    &:before {
        content: $icon-building;
    }
}
.icon-child {
    &:before {
        content: $icon-child;
    }
}
.icon-paw {
    &:before {
        content: $icon-paw;
    }
}
.icon-spoon {
    &:before {
        content: $icon-spoon;
    }
}
.icon-cube {
    &:before {
        content: $icon-cube;
    }
}
.icon-cubes {
    &:before {
        content: $icon-cubes;
    }
}
.icon-behance {
    &:before {
        content: $icon-behance;
    }
}
.icon-behance-square {
    &:before {
        content: $icon-behance-square;
    }
}
.icon-steam {
    &:before {
        content: $icon-steam;
    }
}
.icon-steam-square {
    &:before {
        content: $icon-steam-square;
    }
}
.icon-recycle {
    &:before {
        content: $icon-recycle;
    }
}
.icon-automobile {
    &:before {
        content: $icon-automobile;
    }
}
.icon-car {
    &:before {
        content: $icon-car;
    }
}
.icon-cab {
    &:before {
        content: $icon-cab;
    }
}
.icon-taxi {
    &:before {
        content: $icon-taxi;
    }
}
.icon-tree {
    &:before {
        content: $icon-tree;
    }
}
.icon-spotify {
    &:before {
        content: $icon-spotify;
    }
}
.icon-deviantart {
    &:before {
        content: $icon-deviantart;
    }
}
.icon-soundcloud {
    &:before {
        content: $icon-soundcloud;
    }
}
.icon-database {
    &:before {
        content: $icon-database;
    }
}
.icon-file-pdf-o {
    &:before {
        content: $icon-file-pdf-o;
    }
}
.icon-file-word-o {
    &:before {
        content: $icon-file-word-o;
    }
}
.icon-file-excel-o {
    &:before {
        content: $icon-file-excel-o;
    }
}
.icon-file-powerpoint-o {
    &:before {
        content: $icon-file-powerpoint-o;
    }
}
.icon-file-image-o {
    &:before {
        content: $icon-file-image-o;
    }
}
.icon-file-photo-o {
    &:before {
        content: $icon-file-photo-o;
    }
}
.icon-file-picture-o {
    &:before {
        content: $icon-file-picture-o;
    }
}
.icon-file-archive-o {
    &:before {
        content: $icon-file-archive-o;
    }
}
.icon-file-zip-o {
    &:before {
        content: $icon-file-zip-o;
    }
}
.icon-file-audio-o {
    &:before {
        content: $icon-file-audio-o;
    }
}
.icon-file-sound-o {
    &:before {
        content: $icon-file-sound-o;
    }
}
.icon-file-movie-o {
    &:before {
        content: $icon-file-movie-o;
    }
}
.icon-file-video-o {
    &:before {
        content: $icon-file-video-o;
    }
}
.icon-file-code-o {
    &:before {
        content: $icon-file-code-o;
    }
}
.icon-vine {
    &:before {
        content: $icon-vine;
    }
}
.icon-codepen {
    &:before {
        content: $icon-codepen;
    }
}
.icon-jsfiddle {
    &:before {
        content: $icon-jsfiddle;
    }
}
.icon-life-bouy {
    &:before {
        content: $icon-life-bouy;
    }
}
.icon-life-buoy {
    &:before {
        content: $icon-life-buoy;
    }
}
.icon-life-ring {
    &:before {
        content: $icon-life-ring;
    }
}
.icon-life-saver {
    &:before {
        content: $icon-life-saver;
    }
}
.icon-support {
    &:before {
        content: $icon-support;
    }
}
.icon-circle-o-notch {
    &:before {
        content: $icon-circle-o-notch;
    }
}
.icon-ra {
    &:before {
        content: $icon-ra;
    }
}
.icon-rebel {
    &:before {
        content: $icon-rebel;
    }
}
.icon-resistance {
    &:before {
        content: $icon-resistance;
    }
}
.icon-empire {
    &:before {
        content: $icon-empire;
    }
}
.icon-ge {
    &:before {
        content: $icon-ge;
    }
}
.icon-git-square {
    &:before {
        content: $icon-git-square;
    }
}
.icon-git {
    &:before {
        content: $icon-git;
    }
}
.icon-hacker-news {
    &:before {
        content: $icon-hacker-news;
    }
}
.icon-y-combinator-square {
    &:before {
        content: $icon-y-combinator-square;
    }
}
.icon-yc-square {
    &:before {
        content: $icon-yc-square;
    }
}
.icon-tencent-weibo {
    &:before {
        content: $icon-tencent-weibo;
    }
}
.icon-qq {
    &:before {
        content: $icon-qq;
    }
}
.icon-wechat {
    &:before {
        content: $icon-wechat;
    }
}
.icon-weixin {
    &:before {
        content: $icon-weixin;
    }
}
.icon-paper-plane {
    &:before {
        content: $icon-paper-plane;
    }
}
.icon-send {
    &:before {
        content: $icon-send;
    }
}
.icon-paper-plane-o {
    &:before {
        content: $icon-paper-plane-o;
    }
}
.icon-send-o {
    &:before {
        content: $icon-send-o;
    }
}
.icon-history {
    &:before {
        content: $icon-history;
    }
}
.icon-circle-thin {
    &:before {
        content: $icon-circle-thin;
    }
}
.icon-header {
    &:before {
        content: $icon-header;
    }
}
.icon-paragraph {
    &:before {
        content: $icon-paragraph;
    }
}
.icon-sliders {
    &:before {
        content: $icon-sliders;
    }
}
.icon-share-alt {
    &:before {
        content: $icon-share-alt;
    }
}
.icon-share-alt-square {
    &:before {
        content: $icon-share-alt-square;
    }
}
.icon-bomb {
    &:before {
        content: $icon-bomb;
    }
}
.icon-futbol-o {
    &:before {
        content: $icon-futbol-o;
    }
}
.icon-soccer-ball-o {
    &:before {
        content: $icon-soccer-ball-o;
    }
}
.icon-tty {
    &:before {
        content: $icon-tty;
    }
}
.icon-binoculars {
    &:before {
        content: $icon-binoculars;
    }
}
.icon-plug {
    &:before {
        content: $icon-plug;
    }
}
.icon-slideshare {
    &:before {
        content: $icon-slideshare;
    }
}
.icon-twitch {
    &:before {
        content: $icon-twitch;
    }
}
.icon-yelp {
    &:before {
        content: $icon-yelp;
    }
}
.icon-newspaper-o {
    &:before {
        content: $icon-newspaper-o;
    }
}
.icon-wifi {
    &:before {
        content: $icon-wifi;
    }
}
.icon-calculator {
    &:before {
        content: $icon-calculator;
    }
}
.icon-paypal {
    &:before {
        content: $icon-paypal;
    }
}
.icon-google-wallet {
    &:before {
        content: $icon-google-wallet;
    }
}
.icon-cc-visa {
    &:before {
        content: $icon-cc-visa;
    }
}
.icon-cc-mastercard {
    &:before {
        content: $icon-cc-mastercard;
    }
}
.icon-cc-discover {
    &:before {
        content: $icon-cc-discover;
    }
}
.icon-cc-amex {
    &:before {
        content: $icon-cc-amex;
    }
}
.icon-cc-paypal {
    &:before {
        content: $icon-cc-paypal;
    }
}
.icon-cc-stripe {
    &:before {
        content: $icon-cc-stripe;
    }
}
.icon-bell-slash {
    &:before {
        content: $icon-bell-slash;
    }
}
.icon-bell-slash-o {
    &:before {
        content: $icon-bell-slash-o;
    }
}
.icon-trash {
    &:before {
        content: $icon-trash;
    }
}
.icon-copyright {
    &:before {
        content: $icon-copyright;
    }
}
.icon-at {
    &:before {
        content: $icon-at;
    }
}
.icon-eyedropper {
    &:before {
        content: $icon-eyedropper;
    }
}
.icon-paint-brush {
    &:before {
        content: $icon-paint-brush;
    }
}
.icon-birthday-cake {
    &:before {
        content: $icon-birthday-cake;
    }
}
.icon-area-chart {
    &:before {
        content: $icon-area-chart;
    }
}
.icon-pie-chart {
    &:before {
        content: $icon-pie-chart;
    }
}
.icon-line-chart {
    &:before {
        content: $icon-line-chart;
    }
}
.icon-lastfm {
    &:before {
        content: $icon-lastfm;
    }
}
.icon-lastfm-square {
    &:before {
        content: $icon-lastfm-square;
    }
}
.icon-toggle-off {
    &:before {
        content: $icon-toggle-off;
    }
}
.icon-toggle-on {
    &:before {
        content: $icon-toggle-on;
    }
}
.icon-bicycle {
    &:before {
        content: $icon-bicycle;
    }
}
.icon-bus {
    &:before {
        content: $icon-bus;
    }
}
.icon-ioxhost {
    &:before {
        content: $icon-ioxhost;
    }
}
.icon-angellist {
    &:before {
        content: $icon-angellist;
    }
}
.icon-cc {
    &:before {
        content: $icon-cc;
    }
}
.icon-ils {
    &:before {
        content: $icon-ils;
    }
}
.icon-shekel {
    &:before {
        content: $icon-shekel;
    }
}
.icon-sheqel {
    &:before {
        content: $icon-sheqel;
    }
}
.icon-meanpath {
    &:before {
        content: $icon-meanpath;
    }
}
.icon-buysellads {
    &:before {
        content: $icon-buysellads;
    }
}
.icon-connectdevelop {
    &:before {
        content: $icon-connectdevelop;
    }
}
.icon-dashcube {
    &:before {
        content: $icon-dashcube;
    }
}
.icon-forumbee {
    &:before {
        content: $icon-forumbee;
    }
}
.icon-leanpub {
    &:before {
        content: $icon-leanpub;
    }
}
.icon-sellsy {
    &:before {
        content: $icon-sellsy;
    }
}
.icon-shirtsinbulk {
    &:before {
        content: $icon-shirtsinbulk;
    }
}
.icon-simplybuilt {
    &:before {
        content: $icon-simplybuilt;
    }
}
.icon-skyatlas {
    &:before {
        content: $icon-skyatlas;
    }
}
.icon-cart-plus {
    &:before {
        content: $icon-cart-plus;
    }
}
.icon-cart-arrow-down {
    &:before {
        content: $icon-cart-arrow-down;
    }
}
.icon-diamond {
    &:before {
        content: $icon-diamond;
    }
}
.icon-ship {
    &:before {
        content: $icon-ship;
    }
}
.icon-user-secret {
    &:before {
        content: $icon-user-secret;
    }
}
.icon-motorcycle {
    &:before {
        content: $icon-motorcycle;
    }
}
.icon-street-view {
    &:before {
        content: $icon-street-view;
    }
}
.icon-heartbeat {
    &:before {
        content: $icon-heartbeat;
    }
}
.icon-venus {
    &:before {
        content: $icon-venus;
    }
}
.icon-mars {
    &:before {
        content: $icon-mars;
    }
}
.icon-mercury {
    &:before {
        content: $icon-mercury;
    }
}
.icon-intersex {
    &:before {
        content: $icon-intersex;
    }
}
.icon-transgender {
    &:before {
        content: $icon-transgender;
    }
}
.icon-transgender-alt {
    &:before {
        content: $icon-transgender-alt;
    }
}
.icon-venus-double {
    &:before {
        content: $icon-venus-double;
    }
}
.icon-mars-double {
    &:before {
        content: $icon-mars-double;
    }
}
.icon-venus-mars {
    &:before {
        content: $icon-venus-mars;
    }
}
.icon-mars-stroke {
    &:before {
        content: $icon-mars-stroke;
    }
}
.icon-mars-stroke-v {
    &:before {
        content: $icon-mars-stroke-v;
    }
}
.icon-mars-stroke-h {
    &:before {
        content: $icon-mars-stroke-h;
    }
}
.icon-neuter {
    &:before {
        content: $icon-neuter;
    }
}
.icon-genderless {
    &:before {
        content: $icon-genderless;
    }
}
.icon-facebook-official {
    &:before {
        content: $icon-facebook-official;
    }
}
.icon-pinterest-p {
    &:before {
        content: $icon-pinterest-p;
    }
}
.icon-whatsapp {
    &:before {
        content: $icon-whatsapp;
    }
}
.icon-server {
    &:before {
        content: $icon-server;
    }
}
.icon-user-plus {
    &:before {
        content: $icon-user-plus;
    }
}
.icon-user-times {
    &:before {
        content: $icon-user-times;
    }
}
.icon-bed {
    &:before {
        content: $icon-bed;
    }
}
.icon-hotel {
    &:before {
        content: $icon-hotel;
    }
}
.icon-viacoin {
    &:before {
        content: $icon-viacoin;
    }
}
.icon-train {
    &:before {
        content: $icon-train;
    }
}
.icon-subway {
    &:before {
        content: $icon-subway;
    }
}
.icon-medium {
    &:before {
        content: $icon-medium;
    }
}
.icon-y-combinator {
    &:before {
        content: $icon-y-combinator;
    }
}
.icon-yc {
    &:before {
        content: $icon-yc;
    }
}
.icon-optin-monster {
    &:before {
        content: $icon-optin-monster;
    }
}
.icon-opencart {
    &:before {
        content: $icon-opencart;
    }
}
.icon-expeditedssl {
    &:before {
        content: $icon-expeditedssl;
    }
}
.icon-battery {
    &:before {
        content: $icon-battery;
    }
}
.icon-battery-4 {
    &:before {
        content: $icon-battery-4;
    }
}
.icon-battery-full {
    &:before {
        content: $icon-battery-full;
    }
}
.icon-battery-3 {
    &:before {
        content: $icon-battery-3;
    }
}
.icon-battery-three-quarters {
    &:before {
        content: $icon-battery-three-quarters;
    }
}
.icon-battery-2 {
    &:before {
        content: $icon-battery-2;
    }
}
.icon-battery-half {
    &:before {
        content: $icon-battery-half;
    }
}
.icon-battery-1 {
    &:before {
        content: $icon-battery-1;
    }
}
.icon-battery-quarter {
    &:before {
        content: $icon-battery-quarter;
    }
}
.icon-battery-0 {
    &:before {
        content: $icon-battery-0;
    }
}
.icon-battery-empty {
    &:before {
        content: $icon-battery-empty;
    }
}
.icon-mouse-pointer {
    &:before {
        content: $icon-mouse-pointer;
    }
}
.icon-i-cursor {
    &:before {
        content: $icon-i-cursor;
    }
}
.icon-object-group {
    &:before {
        content: $icon-object-group;
    }
}
.icon-object-ungroup {
    &:before {
        content: $icon-object-ungroup;
    }
}
.icon-sticky-note {
    &:before {
        content: $icon-sticky-note;
    }
}
.icon-sticky-note-o {
    &:before {
        content: $icon-sticky-note-o;
    }
}
.icon-cc-jcb {
    &:before {
        content: $icon-cc-jcb;
    }
}
.icon-cc-diners-club {
    &:before {
        content: $icon-cc-diners-club;
    }
}
.icon-clone {
    &:before {
        content: $icon-clone;
    }
}
.icon-balance-scale {
    &:before {
        content: $icon-balance-scale;
    }
}
.icon-hourglass-o {
    &:before {
        content: $icon-hourglass-o;
    }
}
.icon-hourglass-1 {
    &:before {
        content: $icon-hourglass-1;
    }
}
.icon-hourglass-start {
    &:before {
        content: $icon-hourglass-start;
    }
}
.icon-hourglass-2 {
    &:before {
        content: $icon-hourglass-2;
    }
}
.icon-hourglass-half {
    &:before {
        content: $icon-hourglass-half;
    }
}
.icon-hourglass-3 {
    &:before {
        content: $icon-hourglass-3;
    }
}
.icon-hourglass-end {
    &:before {
        content: $icon-hourglass-end;
    }
}
.icon-hourglass {
    &:before {
        content: $icon-hourglass;
    }
}
.icon-hand-grab-o {
    &:before {
        content: $icon-hand-grab-o;
    }
}
.icon-hand-rock-o {
    &:before {
        content: $icon-hand-rock-o;
    }
}
.icon-hand-paper-o {
    &:before {
        content: $icon-hand-paper-o;
    }
}
.icon-hand-stop-o {
    &:before {
        content: $icon-hand-stop-o;
    }
}
.icon-hand-scissors-o {
    &:before {
        content: $icon-hand-scissors-o;
    }
}
.icon-hand-lizard-o {
    &:before {
        content: $icon-hand-lizard-o;
    }
}
.icon-hand-spock-o {
    &:before {
        content: $icon-hand-spock-o;
    }
}
.icon-hand-pointer-o {
    &:before {
        content: $icon-hand-pointer-o;
    }
}
.icon-hand-peace-o {
    &:before {
        content: $icon-hand-peace-o;
    }
}
.icon-trademark {
    &:before {
        content: $icon-trademark;
    }
}
.icon-registered {
    &:before {
        content: $icon-registered;
    }
}
.icon-creative-commons {
    &:before {
        content: $icon-creative-commons;
    }
}
.icon-gg {
    &:before {
        content: $icon-gg;
    }
}
.icon-gg-circle {
    &:before {
        content: $icon-gg-circle;
    }
}
.icon-tripadvisor {
    &:before {
        content: $icon-tripadvisor;
    }
}
.icon-odnoklassniki {
    &:before {
        content: $icon-odnoklassniki;
    }
}
.icon-odnoklassniki-square {
    &:before {
        content: $icon-odnoklassniki-square;
    }
}
.icon-get-pocket {
    &:before {
        content: $icon-get-pocket;
    }
}
.icon-wikipedia-w {
    &:before {
        content: $icon-wikipedia-w;
    }
}
.icon-safari {
    &:before {
        content: $icon-safari;
    }
}
.icon-chrome {
    &:before {
        content: $icon-chrome;
    }
}
.icon-firefox {
    &:before {
        content: $icon-firefox;
    }
}
.icon-opera {
    &:before {
        content: $icon-opera;
    }
}
.icon-internet-explorer {
    &:before {
        content: $icon-internet-explorer;
    }
}
.icon-television {
    &:before {
        content: $icon-television;
    }
}
.icon-tv {
    &:before {
        content: $icon-tv;
    }
}
.icon-contao {
    &:before {
        content: $icon-contao;
    }
}
.icon-500px {
    &:before {
        content: $icon-500px;
    }
}
.icon-amazon {
    &:before {
        content: $icon-amazon;
    }
}
.icon-calendar-plus-o {
    &:before {
        content: $icon-calendar-plus-o;
    }
}
.icon-calendar-minus-o {
    &:before {
        content: $icon-calendar-minus-o;
    }
}
.icon-calendar-times-o {
    &:before {
        content: $icon-calendar-times-o;
    }
}
.icon-calendar-check-o {
    &:before {
        content: $icon-calendar-check-o;
    }
}
.icon-industry {
    &:before {
        content: $icon-industry;
    }
}
.icon-map-pin {
    &:before {
        content: $icon-map-pin;
    }
}
.icon-map-signs {
    &:before {
        content: $icon-map-signs;
    }
}
.icon-map-o {
    &:before {
        content: $icon-map-o;
    }
}
.icon-map {
    &:before {
        content: $icon-map;
    }
}
.icon-commenting {
    &:before {
        content: $icon-commenting;
    }
}
.icon-commenting-o {
    &:before {
        content: $icon-commenting-o;
    }
}
.icon-houzz {
    &:before {
        content: $icon-houzz;
    }
}
.icon-vimeo {
    &:before {
        content: $icon-vimeo;
    }
}
.icon-black-tie {
    &:before {
        content: $icon-black-tie;
    }
}
.icon-fonticons {
    &:before {
        content: $icon-fonticons;
    }
}
.icon-reddit-alien {
    &:before {
        content: $icon-reddit-alien;
    }
}
.icon-edge {
    &:before {
        content: $icon-edge;
    }
}
.icon-credit-card-alt {
    &:before {
        content: $icon-credit-card-alt;
    }
}
.icon-codiepie {
    &:before {
        content: $icon-codiepie;
    }
}
.icon-modx {
    &:before {
        content: $icon-modx;
    }
}
.icon-fort-awesome {
    &:before {
        content: $icon-fort-awesome;
    }
}
.icon-usb {
    &:before {
        content: $icon-usb;
    }
}
.icon-product-hunt {
    &:before {
        content: $icon-product-hunt;
    }
}
.icon-mixcloud {
    &:before {
        content: $icon-mixcloud;
    }
}
.icon-scribd {
    &:before {
        content: $icon-scribd;
    }
}
.icon-pause-circle {
    &:before {
        content: $icon-pause-circle;
    }
}
.icon-pause-circle-o {
    &:before {
        content: $icon-pause-circle-o;
    }
}
.icon-stop-circle {
    &:before {
        content: $icon-stop-circle;
    }
}
.icon-stop-circle-o {
    &:before {
        content: $icon-stop-circle-o;
    }
}
.icon-shopping-bag {
    &:before {
        content: $icon-shopping-bag;
    }
}
.icon-shopping-basket {
    &:before {
        content: $icon-shopping-basket;
    }
}
.icon-hashtag {
    &:before {
        content: $icon-hashtag;
    }
}
.icon-bluetooth {
    &:before {
        content: $icon-bluetooth;
    }
}
.icon-bluetooth-b {
    &:before {
        content: $icon-bluetooth-b;
    }
}
.icon-percent {
    &:before {
        content: $icon-percent;
    }
}
.icon-gitlab {
    &:before {
        content: $icon-gitlab;
    }
}
.icon-wpbeginner {
    &:before {
        content: $icon-wpbeginner;
    }
}
.icon-wpforms {
    &:before {
        content: $icon-wpforms;
    }
}
.icon-envira {
    &:before {
        content: $icon-envira;
    }
}
.icon-universal-access {
    &:before {
        content: $icon-universal-access;
    }
}
.icon-wheelchair-alt {
    &:before {
        content: $icon-wheelchair-alt;
    }
}
.icon-question-circle-o {
    &:before {
        content: $icon-question-circle-o;
    }
}
.icon-blind {
    &:before {
        content: $icon-blind;
    }
}
.icon-audio-description {
    &:before {
        content: $icon-audio-description;
    }
}
.icon-volume-control-phone {
    &:before {
        content: $icon-volume-control-phone;
    }
}
.icon-braille {
    &:before {
        content: $icon-braille;
    }
}
.icon-assistive-listening-systems {
    &:before {
        content: $icon-assistive-listening-systems;
    }
}
.icon-american-sign-language-interpreting {
    &:before {
        content: $icon-american-sign-language-interpreting;
    }
}
.icon-asl-interpreting {
    &:before {
        content: $icon-asl-interpreting;
    }
}
.icon-deaf {
    &:before {
        content: $icon-deaf;
    }
}
.icon-deafness {
    &:before {
        content: $icon-deafness;
    }
}
.icon-hard-of-hearing {
    &:before {
        content: $icon-hard-of-hearing;
    }
}
.icon-glide {
    &:before {
        content: $icon-glide;
    }
}
.icon-glide-g {
    &:before {
        content: $icon-glide-g;
    }
}
.icon-sign-language {
    &:before {
        content: $icon-sign-language;
    }
}
.icon-signing {
    &:before {
        content: $icon-signing;
    }
}
.icon-low-vision {
    &:before {
        content: $icon-low-vision;
    }
}
.icon-viadeo {
    &:before {
        content: $icon-viadeo;
    }
}
.icon-viadeo-square {
    &:before {
        content: $icon-viadeo-square;
    }
}
.icon-snapchat {
    &:before {
        content: $icon-snapchat;
    }
}
.icon-snapchat-ghost {
    &:before {
        content: $icon-snapchat-ghost;
    }
}
.icon-snapchat-square {
    &:before {
        content: $icon-snapchat-square;
    }
}
.icon-pied-piper {
    &:before {
        content: $icon-pied-piper;
    }
}
.icon-first-order {
    &:before {
        content: $icon-first-order;
    }
}
.icon-yoast {
    &:before {
        content: $icon-yoast;
    }
}
.icon-themeisle {
    &:before {
        content: $icon-themeisle;
    }
}
.icon-google-plus-circle {
    &:before {
        content: $icon-google-plus-circle;
    }
}
.icon-google-plus-official {
    &:before {
        content: $icon-google-plus-official;
    }
}
.icon-fa {
    &:before {
        content: $icon-fa;
    }
}
.icon-font-awesome {
    &:before {
        content: $icon-font-awesome;
    }
}
.icon-handshake-o {
    &:before {
        content: $icon-handshake-o;
    }
}
.icon-envelope-open {
    &:before {
        content: $icon-envelope-open;
    }
}
.icon-envelope-open-o {
    &:before {
        content: $icon-envelope-open-o;
    }
}
.icon-linode {
    &:before {
        content: $icon-linode;
    }
}
.icon-address-book {
    &:before {
        content: $icon-address-book;
    }
}
.icon-address-book-o {
    &:before {
        content: $icon-address-book-o;
    }
}
.icon-address-card {
    &:before {
        content: $icon-address-card;
    }
}
.icon-vcard {
    &:before {
        content: $icon-vcard;
    }
}
.icon-address-card-o {
    &:before {
        content: $icon-address-card-o;
    }
}
.icon-vcard-o {
    &:before {
        content: $icon-vcard-o;
    }
}
.icon-user-circle {
    &:before {
        content: $icon-user-circle;
    }
}
.icon-user-circle-o {
    &:before {
        content: $icon-user-circle-o;
    }
}
.icon-user-o {
    &:before {
        content: $icon-user-o;
    }
}
.icon-id-badge {
    &:before {
        content: $icon-id-badge;
    }
}
.icon-drivers-license {
    &:before {
        content: $icon-drivers-license;
    }
}
.icon-id-card {
    &:before {
        content: $icon-id-card;
    }
}
.icon-drivers-license-o {
    &:before {
        content: $icon-drivers-license-o;
    }
}
.icon-id-card-o {
    &:before {
        content: $icon-id-card-o;
    }
}
.icon-quora {
    &:before {
        content: $icon-quora;
    }
}
.icon-free-code-camp {
    &:before {
        content: $icon-free-code-camp;
    }
}
.icon-telegram {
    &:before {
        content: $icon-telegram;
    }
}
.icon-thermometer {
    &:before {
        content: $icon-thermometer;
    }
}
.icon-thermometer-4 {
    &:before {
        content: $icon-thermometer-4;
    }
}
.icon-thermometer-full {
    &:before {
        content: $icon-thermometer-full;
    }
}
.icon-thermometer-3 {
    &:before {
        content: $icon-thermometer-3;
    }
}
.icon-thermometer-three-quarters {
    &:before {
        content: $icon-thermometer-three-quarters;
    }
}
.icon-thermometer-2 {
    &:before {
        content: $icon-thermometer-2;
    }
}
.icon-thermometer-half {
    &:before {
        content: $icon-thermometer-half;
    }
}
.icon-thermometer-1 {
    &:before {
        content: $icon-thermometer-1;
    }
}
.icon-thermometer-quarter {
    &:before {
        content: $icon-thermometer-quarter;
    }
}
.icon-thermometer-0 {
    &:before {
        content: $icon-thermometer-0;
    }
}
.icon-thermometer-empty {
    &:before {
        content: $icon-thermometer-empty;
    }
}
.icon-shower {
    &:before {
        content: $icon-shower;
    }
}
.icon-bath {
    &:before {
        content: $icon-bath;
    }
}
.icon-bathtub {
    &:before {
        content: $icon-bathtub;
    }
}
.icon-s15 {
    &:before {
        content: $icon-s15;
    }
}
.icon-podcast {
    &:before {
        content: $icon-podcast;
    }
}
.icon-window-maximize {
    &:before {
        content: $icon-window-maximize;
    }
}
.icon-window-minimize {
    &:before {
        content: $icon-window-minimize;
    }
}
.icon-window-restore {
    &:before {
        content: $icon-window-restore;
    }
}
.icon-times-rectangle {
    &:before {
        content: $icon-times-rectangle;
    }
}
.icon-window-close {
    &:before {
        content: $icon-window-close;
    }
}
.icon-times-rectangle-o {
    &:before {
        content: $icon-times-rectangle-o;
    }
}
.icon-window-close-o {
    &:before {
        content: $icon-window-close-o;
    }
}
.icon-bandcamp {
    &:before {
        content: $icon-bandcamp;
    }
}
.icon-grav {
    &:before {
        content: $icon-grav;
    }
}
.icon-etsy {
    &:before {
        content: $icon-etsy;
    }
}
.icon-imdb {
    &:before {
        content: $icon-imdb;
    }
}
.icon-ravelry {
    &:before {
        content: $icon-ravelry;
    }
}
.icon-eercast {
    &:before {
        content: $icon-eercast;
    }
}
.icon-microchip {
    &:before {
        content: $icon-microchip;
    }
}
.icon-snowflake-o {
    &:before {
        content: $icon-snowflake-o;
    }
}
.icon-superpowers {
    &:before {
        content: $icon-superpowers;
    }
}
.icon-wpexplorer {
    &:before {
        content: $icon-wpexplorer;
    }
}
.icon-meetup {
    &:before {
        content: $icon-meetup;
    }
}
.icon-alii-clipboard {
    &:before {
        content: $icon-alii-clipboard;
    }
}
.icon-alii-flowchart {
    &:before {
        content: $icon-alii-flowchart;
    }
}
.icon-alii-text {
    &:before {
        content: $icon-alii-text;
    }
}
.icon-alii-arrow {
    &:before {
        content: $icon-alii-arrow;
    }
}
.icon-alii-book {
    &:before {
        content: $icon-alii-book;
    }
}
.icon-alii-populations {
    &:before {
        content: $icon-alii-populations;
    }
}

.icon-alii-team {
    &:before {
        content: $icon-alii-team;
    }
}

.icon-alii-calendar {
    &:before {
        content: $icon-alii-calendar;
    }
}

.icon-alii-shop {
    &:before {
        content: $icon-alii-shop;
    }
}

.icon-alii-news {
    &:before {
        content: $icon-alii-news;
    }
}

.icon-alii-bookmark {
    &:before {
        content: $icon-alii-bookmark;
    }
}
.icon-alii-file {
    &:before {
        content: $icon-alii-file;
    }
}
.icon-alii-house {
    &:before {
        content: $icon-alii-house;
    }
}
.icon-alii-message-square {
    &:before {
        content: $icon-alii-message-square;
    }
}
.icon-alii-notification {
    &:before {
        content: $icon-alii-notification;
    }
}
.icon-alii-search {
    &:before {
        content: $icon-alii-search;
    }
}
