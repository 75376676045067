/**
 * Subnavigation
 * component
 */

.subnavigation {
  list-style: none;
  margin: 0 ($gutter / 3 * -1) $gutter;
  padding: 0;
  font-size: .9em;
  
  &__link {
    color: inherit;
    display: block;
    font-weight: 500;
    padding: ($gutter / 6) ($gutter / 3);
    text-decoration: none;
    transition: color .6s ease-out;

    &:hover {
      color: $color-secondary;
      text-decoration: none;
      transition: color .3s ease-in-out;
    }

    &.-is-active {
      background-color: $color-base;
      border-radius: $radius-mid;
      color: $color-secondary;
    }
  }

  & & {
    margin: 0 0 $gutter $gutter; 

    .subnavigation__link {
      font-weight: 300;
    }
  }
}
