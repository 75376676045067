/**
 * Dashboard
 * component
 */


/** JEET settings **/

$dashboard_grid: (
    gutter: 1
);

.dashboard {

    @include clearfix();

    &__title {
        font-size: 1.250em;
        color: $color-medium;
        font-family: $font-primary;
        margin-bottom: $gutter / 2;
    }

    &__items {
        @include clearfix();
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__item {
        margin-bottom: $gutter / 3;
        width: 100%;
    }

    /** States **/

    /** Modifiers **/

    &__item.-placeholder {
        background-color: transparent;
        box-shadow: none;
        border: dashed 2px $color-primary;
        min-height: 150px;
        display: flex;
        vertical-align: center;
    }

    /** Breakpoints **/

    @media screen and (min-width: 600px) {
        &__item {
            @include column(6/12, $cycle: 2, $offset: 0, $gutter: map-get($dashboard_grid, 'gutter'));
        }
    }

    @media screen and (min-width: 900px) {
        &__item {
            @include column(4/12, $cycle: 3, $offset: 0, $gutter: map-get($dashboard_grid, 'gutter'));
        }
    }

    @media screen and (min-width: $breakpoint-header) {
        &__item {
            @include column(6/12, $cycle: 2, $offset: 0, $gutter: map-get($dashboard_grid, 'gutter'));
        }
    }

    @media screen and (min-width: 1100px) {
        &__item {
            @include column(4/12, $cycle: 3, $offset: 0, $gutter: map-get($dashboard_grid, 'gutter'));
        }
    }


}
