/**
 * Base
 */


/** Body **/

html,
body {
  background-color: $color-extralight;
  color: $color-dark;
  font-family: $font-base;
  font-size: 100%;
  font-size: 1em;
  line-height: 1.5;
}

.h4, .h5, .h6, h4, h5, h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

/** Box-sizing **/

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
