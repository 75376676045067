/**
 * Wysiwyg
 * component
 */

.wysiwyg {
    color: $color-medium;
    //max-width: 42em;

    h1,
    h2,
    h3,
    h4 {
        color: inherit;
        font-family: $font-primary;
        font-weight: bold;
        margin: 0.67em 0;
        // border: 1px solid #ccc;

        &.-no-margin {
            margin: 0;
        }

        &.is-active {
            color: $color-secondary;
        }
    }

    h1 {
        color: $color-dark;
        font-size: 2.25em;
        line-height: 1;
    }

    h2,
    h3,
    h4 {
        line-height: 1.2;
    }

    h2 {
        font-size: 1.875em;
    }

    h3 {
        font-size: 1.5em;
    }

    h4 {
        font-size: 1.25em;
    }

    h5 {
        font-size: 0.9em;
    }

    p {
        color: inherit;
        font-size: 0.875em;
        line-height: 1.6em;
        margin: 0 0 0.5em;
        // border: 1px solid #ccc;

        &.intro {
            font-size: 1.125em;
            line-height: 1.5;
        }
    }

    table {
        border: 1px solid #ddd;
        border-collapse: collapse;
    }

    td {
        border: 1px solid #ddd;
        font-size: 0.875rem;
        vertical-align: top;
        padding: 4px;
    }

    td * {
        font-size: 1em;
        line-height: 1.25em;
    }

    ul,
    ol {
        font-size: 0.875rem;
        margin: 0 0 0.5em;
        // border: 1px solid #ccc;
    }

    ul {
        list-style-image: url('data:image/svg+xml;base64,DQo8c3ZnIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4IiB2aWV3Qm94PSIwIDAgNiA2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPg0KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNDguMiAoNDczMjcpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPg0KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPg0KICAgIDxkZWZzPjwvZGVmcz4NCiAgICA8ZyBpZD0iU3R5bGVndWlkZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+DQogICAgICAgIDxnIGlkPSIwMi10eXBvZ3JhZmllIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjE5LjAwMDAwMCwgLTgyMC4wMDAwMDApIiBmaWxsPSIjRTc1MTFGIj4NCiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJUcmlhbmdsZS1Db3B5LTIiIHBvaW50cz0iNjI1IDgyMyA2MTkgODI2IDYxOSA4MjAiPjwvcG9seWdvbj4NCiAgICAgICAgPC9nPg0KICAgIDwvZz4NCjwvc3ZnPg==');
        padding: 0 0 0 1em;
    }

    ol {
        list-style-type: decimal-leading-zero;
        padding: 0 0 0 1.8em;
    }

    blockquote {
        color: $color-primary;
        font-family: $font-primary;
        font-size: 1.5em;
        font-weight: 300;
        line-height: 1.4;
        margin: 0 0 0.333em;

        p {
            color: inherit;
            font-family: inherit;
            font-size: inherit;
            line-height: 1.4;
            margin: 0;
        }
    }

    small {
        font-size: 0.625em;
    }

    b,
    strong {
        font-weight: 600;
    }

    i,
    em,
    cite {
        font-style: italic;
    }

    a {
        color: $color-secondary;
        text-decoration: underline;
        transition: color 0.6s ease-out;

        &:hover {
            color: $color-primary;
            transition: color 0.3s ease-in-out;
        }
    }

    /**
    * COMBINATIONS
    */

    // p + p > strong:only-child,
    // p + p > strong:first-child:last-child,
    // p + p > strong:only-of-type {
    //   display: inline-block;
    //   padding-top: 1em;
    // }
}
