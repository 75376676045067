/**
 * Link list
 * component
 */

.link-list {
  background-color: $color-primary;
  border-bottom: 1px solid rgba($color-base, .4);
  margin: 0 0 $gutter;
  padding: 0;

  &__link {
    @extend %list-button;

    &:after {
      content: $icon-angle-right;
      font-family: $font-icon;
      float: right;
      transform: translateX(0);
      transition: transform .6s ease-out;
    }

    &.-is-active {
      background-color: rgba($color-base, .4);
    }

    &:hover {
      background-color: rgba($color-base, .1);
      transition: background-color .3s ease-in-out;

      &:after {
        transform: translateX(.3em);
        transition: transform .3s ease-in-out;
      }
    }
  }
}
