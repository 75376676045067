/**
 * Navigation
 * component
 */

.navigation {

  &__items {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__icon {
    display: inline-block;
    text-align: center;
    width: 6.6em;
  }

  &__label {
    display: inline-block;
    min-width: 15em;
  }

  &__link {
    color: $color-medium;
    display: block;
    font-family: $font-primary;
    font-size: .9em;
    font-weight: bold;
    padding: ($gutter / 2) 0;
    text-decoration: none;
    transition: color .6s ease-out;
    white-space: nowrap;

    &:hover {
      color: $color-secondary;
      text-decoration: none;
      transition: color .3s ease-in-out;
    }

    &.-is-active {
      background-color: rgba($color-primary, .5);
    }

    .navigation__icon > span {
      display: inline-block;
      transform: rotate(0deg);
      transition: transform .4s ease-in-out;
    }

    &.-is-collapsed .navigation__icon > span {
      transform: rotate(180deg);
    }
  }

  &__item {

    &.-desktop-only {
      display: none;
    }

    &.-mobile-only {
      display: inline-block;
    }

    &.-mobile-compact {
      display: inline-block;

      .navigation__icon {
        width: 3em;
      }

      .navigation__label {
        display: none;
      }
    }
  }

  
  /** Breakpoints **/

  @media screen and (min-width: $breakpoint-header) {
    overflow: hidden;

    &__item {
      white-space: nowrap;
      overflow: hidden;

      &.-mobile-compact {
        display: block;

        .navigation__icon {
          width: 6.6em;
        }

        .navigation__label {
          display: inline-block;
        }
      }

      &.-desktop-only {
        display: block;
      }

      &.-mobile-only {
        display: none;
      }
    }

    &.-is-collapsed {
      max-width: 3.6em;
      overflow: hidden;
    }
  }
}
