/**
 * Page
 * component
 */

.page {
  background-color: $color-extralight;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &__content {
    height: 100%;
    left: 0;
    overflow: auto;
    padding: ($space * 1) ($gutter / 3) $gap;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__section {
    background-color: $color-base;
    border-radius: $radius-mid;
    margin: 0 0 $gap;
    padding: $gutter ($gutter / 2) $space;

    &.-small-bottom {
      margin-bottom: $gutter; 
    }

    &.-small-bottom-padding {
      padding-bottom: $gutter;
    }

    &.-no-bottom {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    &.-transparent {
      background-color: transparent;
    }

  }

  &__divider {
    border: none;
    border-bottom: 1px solid $color-light;
    margin: $gutter ($gutter / 2 * -1);
  }

  &__corner {
    position: absolute;

    &.-top {
      top: ($gutter / 2);
    }
    
    &.-left {
      left: ($gutter / 2);
    }

    &.-right {
      right: ($gutter / 2);
    }
  }


  /** Modifiers **/

  &.-small & {
    &__section {
      margin-left: auto;
      margin-right: auto;
      max-width: 42em;
    }
  }

  &.-small-top & {
    &__content {
      padding-top: $space;
    }
  }

  &.-no-padding & {
    &__content {
      padding: 0;
    }

    &__divider {
      margin-left: 0;
      margin-right: 0;
    }
  }


  /** Breakpoints **/


  @media screen and (min-width: $breakpoint-sidebar) {
    .layout {
      &__content {
        margin-top: 3.2em;
      }
    }
  }

  @media screen and (max-width: $breakpoint-sidebar) {
    .page {
      &__content {
        overflow: inherit;
      }
    }
  }

  @media screen and (min-width: $breakpoint-sidebar) {

    &__content {
      padding: ($space * 1) ($gutter / 3) $gap;
      
    }

    &__section {
      padding: $gutter $gutter $space;
    }

    &__divider {
      margin-left: ($gutter * -1);
      margin-right: ($gutter * -1);
    }

    &__corner {
      &.-top {
        top: $gutter;
      }

      &.-left {
        left: $space;
      }

      &.-right {
        right: $gutter;
      }
    }
  }
}
