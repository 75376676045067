/**
 * Button group
 * component
 */

.button-group {
  border: 1px solid $color-light; 
  border-radius: $radius-mid;
  display: inline-flex;
  vertical-align: middle;

  .button {
    border-radius: 0;
  }

  & > .button {

    &:first-child {
      border-top-left-radius: $radius-mid;
      border-bottom-left-radius: $radius-mid;
    }

    &:last-child {
      border-top-right-radius: $radius-mid;
      border-bottom-right-radius: $radius-mid;
    }
  }

  .btn-group {
    border: 0px;

    .button { 
      line-height: inherit;
      border: 0px;

      &:focus{
        outline: none;
      }
    }

    &:first-child .button:first-child {
      border-top-left-radius: $radius-mid;
      border-bottom-left-radius: $radius-mid;
    }
    
    &:last-child .button {
      border-top-right-radius: $radius-mid;
      border-bottom-right-radius: $radius-mid;
    }
  }


  /**
  * modifiers
  */

  &.-borderless {
    border: 0px;
    &:focus {
      outline: none;
    }
  }
  
  &.-toolbar {

    i {
      font-style: normal;
    }

    

    .button {
      background-color: transparent;
      color: $color-medium;
      
      &.is-active {
        color: Red;
      }

      &.is-empty {
        opacity: .5;
      }

      &:hover {
        transition: color .6s ease-out;
        background-color: $color-base;
        color: $color-secondary;
        opacity: 1;
      }
    }
  }

}
