/**
 * Accordion
 * component
 */

.accordion {
  background-color: $color-primary;
  border-bottom: 1px solid rgba($color-base, .4);
  color: $color-base;
  margin: 0 0 $gutter;
  padding: 0;

  &__item {
    width: 100%;
  }

  &__button {
    @extend %list-button;

    &:after {
      content: $icon-angle-down;
      transform: rotate(0deg);
      transition: transform .4s ease-in-out;
    }

    &:hover {
      background-color: rgba($color-base, .1);
      transition: background-color .3s ease-in-out;
    }
  }

  &__content {
    background-color: rgba($color-base, .6);
    color: $color-medium;
    max-height: 0;
    overflow: hidden;
    transition: max-height .6s ease-out;
  }

  &__content-body {
    padding: ($gutter / 2) $gutter;
  }


  /** States **/

  &__item.-is-open & {

    &__button:after {
      transform: rotate(180deg);
    }

    &__content {
      max-height: 500em;
      transition: max-height .9s ease-in-out;
    }
  }
}
