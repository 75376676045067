/**
 * Settings
 */

$color-primary: var(--primary-color);
$color-secondary: var(--secondary-color);
$color-primary-light: var(--primary-color-light);

/** Breakpoints **/
$breakpoint-header: 60em;
$breakpoint-sidebar: 60em;

/** Colors **/

//$color-primary: #7fc5ba;
//$color-secondary: #e7511f;

$color-dark: #000;
$color-medium: #706f6e;
$color-light: #efefef;
$color-extralight: #f9f9f9;
$color-base: #fff;

/** Dimensions **/

$gutter: 1.8em;
$space: ($gutter * 2);
$gap: ($space / 2);

$radius-mid: 4px;

/** Typography **/

$font-base: 'Roboto', sans-serif;
$font-primary: 'Montserrat', sans-serif;
$font-secondary: $font-base;
$font-mono: monospace;
$font-icon: 'aliicon', sans-serif;

/** Z-index **/

$zindex-top: 1000;
$zindex-base: 100;
$zindex-bottom: 1;
