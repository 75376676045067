/**
 * Fonts
 * base
 */

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto-v18-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
       url('../fonts/roboto-v18-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v18-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v18-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v18-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v18-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
       url('../fonts/roboto-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v18-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/roboto-v18-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('../fonts/roboto-v18-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v18-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v18-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v18-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v18-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('../fonts/montserrat-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v12-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/montserrat-v12-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
       url('../fonts/montserrat-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v12-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v12-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* allicon - custom icons + fontawesome */
@font-face {
  font-family: 'aliicon';
  src:  url('../fonts/aliicon.eot?fuac2n');
  src:  url('../fonts/aliicon.eot?fuac2n#iefix') format('embedded-opentype'),
    url('../fonts/aliicon.ttf?fuac2n') format('truetype'),
    url('../fonts/aliicon.woff?fuac2n') format('woff'),
    url('../fonts/aliicon.svg?fuac2n#aliicon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'aliicon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
